<template>
    <div class="infoCenter-page">
        <div class="page-title">
            <span class="name">消息中心</span>
        </div>
            <a-table :columns="columns" @change="PageChange" :data-source="allocationList" :pagination="pagination"  style="padding:20px">
                <template slot="action" slot-scope="text,record">
                    <a @click="toInputs_data(record)">开始工作</a>
                </template>
            </a-table>
    </div>
</template>

<script>
// import { messageTips } from '../../until/tools';
const columns = [
{
    title: '序号',
    dataIndex: 'sort',
    key: '1',
    align:'center',
    width:'100px'
  },
  {
    title: '时间',
    dataIndex: 'time',
    key: '2',
    align:'center',
  },
  {
    title: '管理员',
    dataIndex: 'oper_user_name',
    key: '3',
  },
  {
    title: '最近操作',
    dataIndex: 'behavior',
    key: '4',
  },
    {
    title: '操作',
    key: 'operation',
    align:'center',
    scopedSlots: {
      customRender: 'action',
    },
  },
];
export default {
    data() {
        return {
            allocationList:[],
            columns,
            pagination: {
              total: 0,
              current:1,
              pageSize: 10,//每页中显示10条数据
              // showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
              showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
        }
    },
    mounted() {
      this.user_info_center()
    },
    methods:{
        PageChange(pageCurrent){
          this.pagination.current=pageCurrent.current
          this.user_info_center(pageCurrent.current)
        },
      toInputs_data(record){
        this.$router.push({
          path:"/inputs_data",
          query:{
            cubeId:record.cubeId,
            cubeName:encodeURI(record.cube),
            jobId:record.jobId,
            editable:1
          }
        })
      },
      user_info_center(current){
        this.$http.user_info_center({currentPage:current}).then(res=>{
            if(res.success){
              this.pagination.total=res.page.allSize
              const data =res.list
              if(data!==undefined){
              const temp = data.map((item,index)=>{
                return{
                  key:index+1,
                  sort:++res.page.indexNum,
                  time:item.time,
                  behavior:item.type_name,
                  oper_user_name:item.oper_user_name
                }
              })
              this.allocationList=temp
            }else{
              this.allocationList=[]
            }
          }
        })
      }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/infoCenter.scss';
</style>
