import { render, staticRenderFns } from "./infoCenter.vue?vue&type=template&id=0ad9790d&scoped=true&"
import script from "./infoCenter.vue?vue&type=script&lang=js&"
export * from "./infoCenter.vue?vue&type=script&lang=js&"
import style0 from "./infoCenter.vue?vue&type=style&index=0&id=0ad9790d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad9790d",
  null
  
)

export default component.exports